/* Settings */
/* Color Variables */
/* Fonts */
/*Text Variables*/
/* Media Queries */
body {
  margin: 0; }

.main-container {
  height: 100%; }

.login-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: url("assets/images/login_background-1x.jpg");
  background-size: cover; }
  .login-view .logged-in-as {
    padding: 2rem 0;
    font-size: 20px; }
  .login-view .login-container, .login-view .change-password-container, .login-view .forgot-password-container {
    width: 40%;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 2px; }
    .login-view .login-container h3, .login-view .change-password-container h3, .login-view .forgot-password-container h3 {
      margin-bottom: 20px; }
  .login-view .login-container {
    height: unset;
    padding-bottom: 15px; }
  .login-view .change-password-container {
    height: unset;
    padding-bottom: 15px; }
    .login-view .change-password-container .password-confirmation {
      display: inline-block; }
  .login-view .forgot-password-container {
    height: unset;
    padding-bottom: 15px; }

@media screen and (max-width: 1200px) {
  .login-view .login-container, .login-view .change-password-container, .login-view .forgot-password-container, .change-password-page .login-container, .change-password-page .change-password-container, .change-password-page .forgot-password-container {
    width: 55%; } }

@media screen and (max-width: 800px) {
  .login-view .login-container, .login-view .change-password-container, .login-view .forgot-password-container, .change-password-page .login-container, .change-password-page .change-password-container, .change-password-page .forgot-password-container {
    width: 75%; } }

@media screen and (max-width: 530px) {
  .login-view .login-container, .login-view .change-password-container, .login-view .forgot-password-container, .change-password-page .login-container, .change-password-page .change-password-container, .change-password-page .forgot-password-container {
    width: 92%; }
  .login-form-inputs svg {
    margin-bottom: -15px !important; }
  .login-view .form-group div {
    width: 200px !important;
    text-align: left;
    color: #525252 !important; } }

@media screen and (max-width: 330px) {
  .login-form-inputs svg {
    margin-bottom: -2px !important; } }

.login-text {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #525252;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

.login-view .form-group span {
  position: absolute !important;
  left: 150px !important;
  bottom: 7px !important;
  font-weight: 600 !important;
  cursor: default !important; }

.login-view .form-group span svg {
  color: #525252 !important; }

@media screen and (min-width: 531px) {
  .login-view .form-group div {
    width: 350px !important;
    text-align: left;
    color: #525252 !important; } }

.login-view .form-group {
  margin-bottom: 1.5rem; }

.login-submit button {
  background-color: #1E88E5 !important;
  box-shadow: 0px 3px 11px #888888; }

.reset-password-submit button {
  background-color: #1E88E5 !important;
  box-shadow: 0px 3px 11px #888888; }

.login-submit span {
  text-transform: none !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px !important; }

.login-button {
  margin-bottom: 1rem; }

.forgot-pass {
  color: #E0E0E0;
  text-decoration: none; }

.forgot-pass:hover {
  color: #1E88E5; }

* {
  box-sizing: border-box; }

html, body, #root {
  min-height: 100%;
  height: 100%;
  width: 100%;
  float: left; }

#App {
  float: left;
  width: 100%;
  height: 100%;
  padding-bottom: 50px; }

.views, .inner {
  float: left;
  width: 100%;
  height: 100%; }

#App.login {
  min-height: 100%;
  height: 100%; }
  #App.login .views, #App.login .inner {
    min-height: 100%;
    height: 100%; }

body {
  font-family: "Roboto", sans-serif;
  background-color: #F1F1F1; }

h1 {
  color: #616161;
  font-weight: 300; }

h2, h3, h4, h5, h6 {
  color: #1E324B;
  font-weight: 700; }

.arrow::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 11px solid white;
  position: absolute;
  top: -10px;
  right: 54px;
  z-index: 1; }

.arrow::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid white;
  position: absolute;
  top: -11px;
  right: 54px;
  z-index: 0; }

.overflow-hidden {
  overflow: hidden; }

a {
  text-decoration: none; }

label {
  color: #1E324B !important; }

.gray-button {
  background-color: #616161 !important; }

.blue-button {
  background-color: #1E88E5 !important; }

.logo-img {
  height: 80%;
  background-image: url("assets/images/ss_branding_2018.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 5px; }

.menus a {
  text-decoration: none; }

.adminusers .admin-dashboard > a,
.adminusersarchived .admin-dashboard > a {
  float: right;
  padding-top: 2%;
  padding-right: 2%; }

@media screen and (max-width: 1500px) {
  #App .background-image {
    padding-top: 75px; }
    #App .background-image .main-container {
      height: 100%; } }

.list-view, .mobile-list-view {
  width: 96%;
  margin: 2% 2%;
  float: left;
  border-radius: 5px 5px 0 0;
  box-shadow: #1d251f 0 1px 11px 0; }

.form-section .list-view {
  border-radius: 0;
  margin: 0;
  width: 100%;
  box-shadow: none;
  float: none; }

.list-view.table-view div:nth-of-type(2) div div, .form-block.table-view div:nth-of-type(2) div div, .transactions-dialog-container div:nth-of-type(2) div div, .desktop-images div:nth-of-type(2) div div {
  justify-content: flex-start; }
  .list-view.table-view div:nth-of-type(2) div div div:nth-of-type(2), .form-block.table-view div:nth-of-type(2) div div div:nth-of-type(2), .transactions-dialog-container div:nth-of-type(2) div div div:nth-of-type(2), .desktop-images div:nth-of-type(2) div div div:nth-of-type(2) {
    width: 20% !important; }
    .list-view.table-view div:nth-of-type(2) div div div:nth-of-type(2) input, .form-block.table-view div:nth-of-type(2) div div div:nth-of-type(2) input, .transactions-dialog-container div:nth-of-type(2) div div div:nth-of-type(2) input, .desktop-images div:nth-of-type(2) div div div:nth-of-type(2) input {
      height: 90% !important;
      margin-bottom: 15px !important;
      border-bottom: 1px solid lightgray !important; }

.table-style {
  background-color: #1E88E5;
  color: white !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 22px !important;
  border-radius: 5px 5px 0 0; }

.mobile-images {
  display: none; }

.desktop-images {
  display: block; }

.missionary-dashboard-mobile-view, .missionary-advance-request-mobile {
  display: none; }

.missionary-dashboard-table-view, .missionary-advance-request-desktop {
  display: block; }

@media screen and (max-width: 1200px) {
  .missionary-dashboard-mobile-view, .missionary-advance-request-mobile, .mobile-images {
    display: block; }
  .missionary-dashboard-table-view, .missionary-advance-request-desktop, .desktop-images {
    display: none; } }

.inline-inputs {
  float: left;
  margin: 0 40px; }

.form-heading {
  margin: 2% 2% 0;
  font-weight: 500;
  color: #616161;
  font-family: "Roboto", sans-serif; }

.form-block {
  float: left;
  width: 96%;
  margin: 2% 2%;
  box-shadow: #1d251f 0 1px 11px 0;
  border-radius: 5px;
  background-color: white; }
  .form-block form {
    width: 100%;
    float: left;
    padding: 3% 0; }

.form-buttons {
  text-align: right;
  margin: 0 2%; }
  .form-buttons div {
    margin: 0 5px; }

.message-list-items {
  background-color: white; }

.message-list-subheader {
  background-color: white;
  padding: 0 2%; }

.login-form-inputs svg, .forgot-password-form-inputs svg, .reset-password-form-inputs svg {
  height: 32px !important;
  width: 32px !important;
  margin-bottom: -8px;
  margin-right: 5px; }

.new-report-section {
  padding: 0 3% 3% 3%;
  float: left;
  width: 100%; }

@media screen and (max-width: 500px) {
  .edit-report-form-buttons {
    float: left;
    width: 100%;
    margin: 3% auto !important;
    display: block !important; } }

.advance-page-inputs {
  display: inline-block;
  float: left;
  margin-right: 40px; }

.advance-page-form-buttons {
  clear: both; }

@media screen and (max-width: 800px) {
  .advance-page-form-buttons {
    clear: both;
    text-align: left; } }

.form-group .custom-disabled input:disabled {
  color: inherit !important;
  cursor: auto !important; }

.list-item-style .dollar_amount {
  color: #525252;
  font-family: "Roboto", sans-serif; }

/* Process modal styling */
.process-title {
  text-align: center; }

.processed-desc {
  text-align: center;
  font-size: 16px; }

.top-block {
  margin: 2rem; }

.bottom-block {
  margin: 20px auto 0; }
  .bottom-block .payout-container {
    width: 170px;
    display: inline-block; }

.expense-total-block {
  display: flex;
  justify-content: space-around;
  flex-direction: row; }

.fix {
  clear: both; }

.submit-block {
  text-align: center; }

.forgot-password-text {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  color: #525252;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

.forgot-password-desc-text {
  color: #E0E0E0;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-bottom: 2.5rem; }

.forgot-password-submit button {
  background-color: #1E88E5 !important;
  box-shadow: 0px 3px 11px #888888; }

.forgot-password-submit span {
  text-transform: none !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px !important; }

.report-total-cost {
  font-family: "Roboto", sans-serif;
  color: #525252;
  font-size: 20px;
  font-weight: 500 !important; }

#type button {
  fill: black !important; }

#expense_code button {
  fill: black !important; }

.unprocessed-expense-reports tr td:nth-of-type(7) {
  padding-left: 0 !important; }

.admin-dashboard .admin-dashboard-table tr th:first-of-type {
  padding-right: 0 !important;
  width: 40px !important; }

.admin-dashboard .admin-dashboard-table tr th:nth-of-type(8) {
  width: 25%; }

.admin-dashboard .admin-dashboard-table tr th:last-of-type {
  width: 1%; }

.admin-dashboard .admin-dashboard-table tr td:first-of-type {
  padding-left: 18px !important; }

.admin-dashboard .admin-dashboard-table tr td:nth-of-type(8) {
  padding-left: 0 !important; }

.missionary-expense-report-container {
  height: 100%; }

.expense-report-form-wrap {
  text-align: center;
  width: 50%;
  margin: 40px auto;
  padding-bottom: 50px;
  background-color: #FFFFFF; }
  .expense-report-form-wrap .header {
    height: 50px;
    padding-top: 5px;
    margin-bottom: 25px; }

@media screen and (max-width: 1500px) {
  .missionary-dashboard-table-view table tbody tr td {
    padding-right: 0px !important; }
  .missionary-form-heading {
    padding-bottom: 1rem; } }

@media screen and (max-width: 420px) {
  .missionary-form-heading {
    font-size: 18px; } }

.missionary-new-report .form-block .form-section div {
  margin-left: 0; }

.new-report-section .message-list-subheader {
  padding-left: 0px !important; }

@media screen and (max-width: 800px) {
  .mobile-span-report-total-cost {
    display: block !important;
    width: 100% !important;
    float: left !important;
    text-align: left !important; } }

.inputs-container {
  float: left;
  width: 100%; }

.fica-check {
  float: left;
  margin-top: 45px; }

.fed-check {
  float: left;
  width: 200px; }

.payroll-inputs {
  float: left;
  margin: 0 40px; }

@media screen and (max-width: 1000px) {
  .inputs-container {
    text-align: center;
    float: none; }
  .fica-check, .fed-check {
    width: 100%;
    margin-top: 20px; }
  .fed-check label {
    width: 150px !important; }
  .fica-check label {
    width: 30px !important; }
  .payroll-inputs {
    width: 100%;
    display: block; }
    .payroll-inputs .payroll-input {
      max-width: 100px; } }

.settings-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 15px; }

form {
  text-align: center;
  margin-bottom: 15px; }

.table-container {
  text-align: center; }
  .table-container .add-button {
    margin-top: 10px;
    margin-bottom: 10px; }

.settings-dialog-container {
  margin-top: 10px;
  text-align: center; }
  .settings-dialog-container .category-inputs {
    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 0; }
  .settings-dialog-container .add-category {
    margin-right: 10px; }

.action-buttons .edit-category {
  margin-right: 5px; }

h3 {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-left: 15px; }

.checkbox-container {
  max-width: 250px;
  display: block;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 8px; }
  .checkbox-container .checkboxes {
    max-width: 110px; }

.category-action-button {
  margin-right: 5px; }

.amount-requested {
  width: 120px; }

.request-date-block {
  margin-left: 55px; }

.request-date-block.inline-inputs, .request-type-block.inline-inputs, .request-hold-account-block.inline-inputs {
  margin: 0; }

.advance-requests table tr th:first-of-type {
  width: 6%; }

.advance-requests table tr th:nth-of-type(2) {
  width: 20%; }

.advance-requests table tr th:nth-of-type(3) {
  width: 8%; }

.advance-requests table tr th:nth-of-type(7) {
  width: 9%; }

.advance-requests table tr th:nth-of-type(8) {
  width: 20%; }

.advance-requests table tr th:last-of-type {
  width: 1%; }

.unprocessed-advance-requests table tr th:first-of-type {
  width: 6%; }

.unprocessed-advance-requests table tr th:nth-of-type(2) {
  width: 20%; }

.advance-requests-by-user table thead tr th:first-of-type {
  width: 6%; }

.advance-requests-by-user table thead tr th:last-of-type {
  width: 24%; }

.advance-requests-by-user table thead tr th:nth-of-type(3) {
  width: 12%; }

.advance-requests-by-user table thead tr th:nth-of-type(4) {
  width: 10%; }

.donations-dialog-container, .transactions-dialog-container {
  text-align: center; }
  .donations-dialog-container .donation-action-button, .transactions-dialog-container .donation-action-button {
    margin-right: 5px; }

.curved-style {
  background-color: #1E88E5;
  color: white !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 22px !important;
  border-radius: 5px 5px 5px 5px; }

.donation-list-item, .distributions-list-item {
  width: 60%;
  float: left;
  margin-bottom: 10px; }
  .donation-list-item p, .distributions-list-item p {
    margin-top: 2px;
    margin-bottom: 2px; }

.donation-list, .distributions-list {
  display: none; }

.donation-table, .distributions-table {
  display: block; }

@media screen and (max-width: 800px) {
  .donation-list, .distributions-list {
    display: block; }
  .donation-table, .distributions-table {
    display: none; } }

.hold-accounts-dialog-container, .recurring-dialog-container {
  text-align: center; }
  .hold-accounts-dialog-container h4, .recurring-dialog-container h4 {
    margin: 0;
    padding: 0; }
  .hold-accounts-dialog-container .hold-account-action-button, .hold-accounts-dialog-container .recurring-action-button, .recurring-dialog-container .hold-account-action-button, .recurring-dialog-container .recurring-action-button {
    margin-right: 5px;
    margin-top: 10px; }

.recurring-transactions {
  margin-left: 69px !important; }

.hold-account-list-item {
  width: 60%;
  float: left;
  margin-bottom: 10px; }
  .hold-account-list-item p {
    margin-top: 2px;
    margin-bottom: 2px; }

.hold-accounts-list {
  display: none; }

.hold-accounts-table {
  display: block;
  text-align: center; }
  .hold-accounts-table th:first-of-type {
    width: 18%; }
  .hold-accounts-table th:nth-of-type(5) {
    width: 10%; }
  .hold-accounts-table th:last-of-type {
    width: 16%; }

.transactions-dialog-container table tr th:first-of-type {
  width: 10%; }

.transactions-dialog-container table tr th:nth-of-type(2) {
  width: 18%; }

.transactions-dialog-container table tr th:nth-of-type(3) {
  width: 8%; }

.add-acct-button {
  margin-top: 10px;
  margin-bottom: 10px; }

@media screen and (max-width: 800px) {
  .hold-accounts-list {
    display: block; }
  .hold-accounts-table {
    display: none; } }

.distribution-form {
  background: white;
  padding: 1%; }
  .distribution-form .buttons-container {
    display: flex;
    justify-content: space-between; }

.distributions-form-container {
  width: 90%;
  margin: 30px auto; }
  .distributions-form-container p {
    margin: 0; }
    .distributions-form-container p:last-of-type {
      margin-bottom: 30px; }
  .distributions-form-container #distributions-form {
    margin-bottom: 30px; }
  .distributions-form-container .top-section .ts-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px; }
  .distributions-form-container .top-section button {
    color: white;
    background-color: #0642C5;
    position: absolute;
    right: 0px;
    bottom: 12px; }
  .distributions-form-container .side-by-side {
    display: flex;
    justify-content: space-between; }
    .distributions-form-container .side-by-side .one-third-section {
      width: 33%;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      padding: 0; }
      .distributions-form-container .side-by-side .one-third-section .inputs, .distributions-form-container .side-by-side .one-third-section .bottom-inputs {
        width: 222px;
        margin: 0 auto; }
        .distributions-form-container .side-by-side .one-third-section .inputs .prefix, .distributions-form-container .side-by-side .one-third-section .bottom-inputs .prefix {
          left: -24px !important;
          top: 41px; }
        .distributions-form-container .side-by-side .one-third-section .inputs button, .distributions-form-container .side-by-side .one-third-section .bottom-inputs button {
          color: white;
          background-color: #0642C5;
          position: absolute;
          right: 10px;
          bottom: 12px; }
      .distributions-form-container .side-by-side .one-third-section .bottom-inputs .prefix {
        left: -12px !important; }
  .distributions-form-container .balance-red span {
    color: red !important; }
  .distributions-form-container .balance-red input {
    color: red !important; }

.dist-style {
  color: white !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  height: 45px;
  text-align: center; }

.distribution {
  background-color: #0642C5; }

.payroll {
  background-color: #04B431; }

.expenses {
  background-color: #D40A0A; }

.radio-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .radio-buttons .radio-button-container {
    width: 110px;
    margin-bottom: 10px; }
    .radio-buttons .radio-button-container .submit-Status {
      margin-bottom: 10px; }
    .radio-buttons .radio-button-container h5 {
      margin: 4px; }

.action-buttons {
  text-align: center; }

.mobile-total {
  display: none; }

.distribution-tab-individual {
  background-color: #1E324B !important; }

.distribution-tab-batch {
  background-color: #616161 !important; }

.batch-distributions-form-container {
  text-align: center; }
  .batch-distributions-form-container #batch-distributions-form .batch-datepicker {
    display: inline-block;
    margin: 20px;
    margin-top: 35px; }

@media screen and (max-width: 700px) {
  .distributions-form-container .top-section {
    align-items: center; }
    .distributions-form-container .top-section .ts-inputs {
      flex-direction: column;
      align-items: center;
      margin: 0 auto; }
      .distributions-form-container .top-section .ts-inputs .prefix {
        left: -13px; }
  .distributions-form-container .side-by-side {
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .distributions-form-container .side-by-side .one-third-section {
      width: 100%;
      align-items: center; }
    .distributions-form-container .side-by-side .one-half-section {
      text-align: center;
      align-items: center; }
      .distributions-form-container .side-by-side .one-half-section .inputs {
        margin-left: 0; }
    .distributions-form-container .side-by-side .comments {
      margin: 0; }
  .distributions-form-container .mobile-total {
    display: inline-block;
    margin-bottom: 10px !important; }
  .distributions-form-container .full-screen-totals {
    display: none !important; }
  .distributions-form-container .dist-style {
    text-align: left;
    width: 70% !important; } }

.fica_check_box div div {
  width: 25px !important;
  margin-left: 0; }

.users-table tr th:first-of-type {
  width: 215px !important; }

.users-table tr td:nth-of-type(5) {
  text-align: center !important; }
